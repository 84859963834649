import {
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useGetSitesQuery } from "../../store/rtk/metamod";
import { useNavigate, useParams } from "react-router-dom";

const SiteList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const sites = useGetSitesQuery();
  if (sites.isLoading) {
    return <CircularProgress />;
  }
  if (sites.data?.status !== "success") {
    return <p>An error occurred</p>;
  }

  return (
    <List>
      {sites.data.data.map((site) => (
        <ListItem key={site.id} disablePadding>
          <ListItemButton
            onClick={() => navigate(`/sites/${site.id}`)}
            selected={site.id === params.siteId}
          >
            <ListItemText primary={site.domain} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default SiteList;
