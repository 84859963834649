type StatusItem = {
  chars: number;
  width: number;
  target: number;
  color: "success" | "error" | "warning";
  progress: number;
  error?: string;
};
type Status = {
  title: StatusItem;
  desc: StatusItem;
};

const getWidth = (
  input: string,
  variant: "title" | "desc" = "title",
): StatusItem => {
  const c = document.createElement("canvas");
  const ctx = c.getContext("2d");
  let maxWidth = 600;
  let okWidth = 480;
  let minWidth = 350;

  if (variant === "desc") {
    maxWidth = 920;
    okWidth = 540;
    minWidth = 400;
  }

  if (ctx) {
    ctx.clearRect(0, 0, c.width, c.height);
    ctx.font = variant === "title" ? "20px Arial" : "14px Arial";
    const width = Math.round(ctx.measureText(input).width);

    let color: StatusItem["color"] = "success";
    let error: StatusItem["error"] = undefined;

    if (width >= maxWidth) {
      color = "error";
      error = `${variant === "title" ? "Title" : "Description"} is too long`;
    } else if (width < minWidth) {
      color = "error";
      error = `${variant === "title" ? "Title" : "Description"} is too short`;
    } else if (width < okWidth) {
      color = "warning";
      error = `${
        variant === "title" ? "Title" : "Description"
      } is acceptable, but try to make it a bit longer.`;
    }

    return {
      chars: input.length,
      width: width,
      target: maxWidth,
      color,
      error,
      progress: width / maxWidth > 1 ? 100 : (width / maxWidth) * 100,
    };
  }
  return {
    chars: 0,
    width: 0,
    target: maxWidth,
    color: "warning",
    progress: 0,
  };
};

export type { Status, StatusItem };
export default getWidth;
