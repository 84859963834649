import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import AuthCheck from "./Components/Auth/AuthCheck";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import type {} from "@mui/lab/themeAugmentation";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

declare module "@mui/material/styles" {
  interface TypographyVariants {
    serpTitle: React.CSSProperties;
    serpHostname: React.CSSProperties;
    serpDesc: React.CSSProperties;
    serpBreadcrumbs: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    serpTitle?: React.CSSProperties;
    serpHostname?: React.CSSProperties;
    serpDesc?: React.CSSProperties;
    serpBreadcrumbs?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    serpTitle: true;
    serpHostname: true;
    serpDesc: true;
    serpBreadcrumbs: true;
  }
}

const theme = createTheme({
  palette: {
    background: {
      default: "#ededed",
      paper: "#fff",
    },
  },
  typography: {
    serpTitle: {
      fontFamily: "Arial",
      fontSize: "20px",
      color: "blue",
      display: "block",
    },
    serpHostname: {
      display: "block",
      fontSize: 14,
      lineHeight: "20px",
    },
    serpBreadcrumbs: {
      display: "block",
      fontSize: 12,
      textOverflow: "ellipsis",
      overflowX: "hidden",
      overflowY: "hidden",
      width: 200,
      height: 18,
      flexWrap: "nowrap",
    },
    serpDesc: {
      color: "black",
      display: "block",
      fontFamily: "Arial",
      fontSize: "14px",
      lineHeight: 1.58,
    },
    h1: {
      fontSize: 28,
      fontWeight: 600,
    },
    h2: {
      fontSize: 28,
      fontWeight: 400,
    },
    h3: {
      fontSize: 24,
      fontWeight: 400,
    },
    h4: {
      fontSize: 20,
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        color: "primary",
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
        color: "primary",
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: "outlined",
        margin: "normal",
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GoogleOAuthProvider clientId="174295992221-9r8r4s7um9k0or9bpella82dikb44df1.apps.googleusercontent.com">
        <Provider store={store}>
          <AuthCheck>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthCheck>
        </Provider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
