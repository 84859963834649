import { useParams } from "react-router-dom";
import { useGetPageQuery, useAddModMutation } from "../../store/rtk/metamod";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { TModification, TPage } from "../../types/sites";
import SerpItem from "./SerpItem";
import getWidth, { Status } from "../../utils/getWidth";
import LinearProgressWithLabel from "../Utils/LinearProgressWithLabel";

const initialMod: Partial<TModification> = {
  desc: "",
  title: "",
  schema: {},
};
const initialPage: TPage = {
  id: 0,
  href: "",
  desc: "",
  keyword: "",
  modifications: [initialMod as TModification],
  siteId: "",
  schema: {},
  title: "",
  errors: [],
  warnings: [],
};

const SinglePage = () => {
  const [addMod] = useAddModMutation();
  const [mod, setMod] = useState(initialMod);
  const [changed, setChanged] = useState(false);
  const [page, setPage] = useState<TPage | null>(null);
  const [status, setStatus] = useState<Status>({
    title: getWidth(""),
    desc: getWidth(""),
  });
  const params = useParams();
  const { isError, isLoading, isSuccess, data } = useGetPageQuery({
    siteId: params.siteId || "",
    pageId: Number(params.pageId) || 0,
  });
  useEffect(() => {
    if (data?.data) {
      setPage(data.data);
      if (data.data.modifications[0]) {
        setMod(data.data.modifications[0]);
      } else {
        setMod({
          title: data.data.title,
          desc: data.data.desc,
          schema: {},
        });
      }
    } else {
      setPage(initialPage);
    }
  }, [data]);

  useEffect(() => {
    setStatus({
      title: getWidth(mod.title || ""),
      desc: getWidth(mod.desc || "", "desc"),
    });
  }, [mod]);

  if (isLoading || !page) {
    return <CircularProgress />;
  }
  if (!params.pageId || isError || !isSuccess) {
    return null;
  }

  const handleSave = () => {
    if (!mod.title || !mod.desc) {
      console.error("Title or desc is undefined");
      return;
    }
    let tmp = data.data?.modifications[0] || data.data;

    if (
      tmp?.title === mod.title &&
      tmp.desc === mod.desc &&
      tmp.schema === mod.schema
    ) {
      setChanged(false);
      return;
    }
    addMod({
      pageId: Number(params.pageId),
      title: mod.title,
      desc: mod.desc,
      schema: mod.schema || {},
    });
    setChanged(false);
  };

  return (
    <>
      <SerpItem page={{ ...page, ...mod }} />
      <TextField
        label="Focus Keyword"
        placeholder="Your focus keyword for this page"
        value={page.keyword || ""}
        onChange={(e) => {
          setPage({
            ...page,
            keyword: e.target.value,
          });
        }}
      />
      <TextField
        label="Meta Title"
        placeholder="Insert your meta title override"
        value={mod.title || ""}
        onChange={(e) => {
          setMod({
            ...mod,
            title: e.target.value,
          });
          setChanged(true);
        }}
      />
      <LinearProgressWithLabel
        value={status.title.progress}
        width={status.title.width}
        target={status.title.target}
        color={status.title.color}
      />
      {status.title.error && (
        <Typography variant="body2" color={`${status.title.color}.main`}>
          {status.title.error}
        </Typography>
      )}
      <TextField
        label="Meta Description"
        placeholder="Insert meta description override"
        multiline
        value={mod.desc || ""}
        onChange={(e) => {
          setMod({
            ...mod,
            desc: e.target.value,
          });
          setChanged(true);
        }}
      />
      <LinearProgressWithLabel
        value={status.desc.progress}
        width={status.desc.width}
        target={status.desc.target}
        color={status.desc.color}
      />
      {status.desc.error && (
        <Typography variant="body2" color={`${status.desc.color}.main`}>
          {status.desc.error}
        </Typography>
      )}
      <Button onClick={handleSave} disabled={!changed}>
        Save changes
      </Button>
    </>
  );
};

export default SinglePage;
