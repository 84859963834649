import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useGetLocLangQuery,
  useAddSiteMutation,
  useGscListPropertiesQuery,
  useGetSitesQuery,
  useEditSiteMutation,
} from "../../store/rtk/metamod";
import { fromUrl, parseDomain } from "parse-domain";
import { LoadingButton } from "@mui/lab";
import { TSite } from "../../types/sites";

const initialState = {
  domain: "",
  location: 2840,
  language: "en",
  siteUrl: "",
  permissionLevel: "",
};

type SiteDialogProps = {
  open: boolean;
  id?: string;
  setOpen: (open: boolean) => void;
};

const SiteDialog = ({ open, setOpen, id }: SiteDialogProps) => {
  const locLang = useGetLocLangQuery();
  const gscSites = useGscListPropertiesQuery();
  const [initiated, setInitiated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [site, setSite] = useState<Partial<TSite>>(initialState);
  const siteList = useGetSitesQuery();
  const [addSite] = useAddSiteMutation();
  const [editSite] = useEditSiteMutation();

  useEffect(() => {
    if (id) {
      if (!siteList.isFetching && !locLang.isFetching) {
        const s = siteList.data?.data?.find((p) => p.id === id);
        if (s) {
          setSite({
            domain: s.domain,
            siteUrl: s.siteUrl,
            permissionLevel: s.permissionLevel,
            language: s.language,
            location: s.location,
          });
        } else {
          setOpen(false);
        }
        setInitiated(true);
      }
    } else {
      setInitiated(true);
    }
  }, [id, siteList, locLang]);

  const handleSubmit = (e: React.FormEvent) => {
    console.log(e);
    e.preventDefault();
    // if (error) return false;
    setLoading(true);
    new Promise<void>((res, rej) => {
      if (id) {
        res();
        return editSite({ id, ...site });
      }
      res();
      return addSite(site);
    })
      .then(() => {
        setSite(initialState);
        setLoading(false);
        setOpen(false);
      })
      .catch((err: Error) => {
        setLoading(false);
        alert("An error occurred");
        console.error(err);
      });
  };

  const handleScDomainSelect = (e: SelectChangeEvent) => {
    const domain = verifyDomain(e.target.value.replace("sc-domain:", ""));
    setSite({
      ...site,
      siteUrl: e.target.value,
      domain: domain,
      permissionLevel:
        gscSites.data?.data?.properties.find(
          (prop) => prop.siteUrl === e.target.value,
        )?.permissionLevel || null,
    });
  };
  const handleClose = () => {
    setSite(initialState);
    setOpen(false);
  };

  const verifyDomain = (domain: string): string => {
    const parsed = parseDomain(fromUrl(domain));
    if (
      parsed.type !== "LISTED" ||
      parsed.hostname === parsed.topLevelDomains.join(".")
    ) {
      setError(true);
    } else {
      setError(false);
    }
    return parsed.hostname.toString().replace(/^www\./g, "");
  };

  const handleDomainChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSite({
      ...site,
      domain: verifyDomain(e.target.value),
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Add site</DialogTitle>
      {initiated &&
      locLang.data?.status === "success" &&
      gscSites.data?.status === "success" ? (
        <form onSubmit={handleSubmit}>
          <DialogContent sx={{ m: 0, py: 2 }}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl fullWidth autoFocus>
                  <InputLabel>Search Console Property</InputLabel>
                  <Select
                    label="Search Console Property"
                    onChange={handleScDomainSelect}
                    value={site.siteUrl || ""}
                  >
                    {gscSites.data.data.properties.map(
                      (property: any, key: any) => {
                        return (
                          <MenuItem key={key} value={property.siteUrl}>
                            <ListItemText
                              primary={property.siteUrl.replace(
                                "sc-domain:",
                                "",
                              )}
                              secondary={
                                property.siteUrl.includes("sc-domain:")
                                  ? "Domain Property"
                                  : undefined
                              }
                            />
                          </MenuItem>
                        );
                      },
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  placeholder="metamod.app"
                  label="Domain name"
                  value={site.domain}
                  disabled={site.siteUrl !== ""}
                  onChange={handleDomainChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disableClearable
                  options={locLang.data.data.languages}
                  onChange={(e, val) =>
                    setSite({ ...site, language: val.language_code })
                  }
                  value={locLang.data.data.languages.find(
                    (lang) => lang.language_code === site.language,
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Language" />
                  )}
                  getOptionLabel={(option) => option.language_name}
                  isOptionEqualToValue={(option, value) =>
                    option.language_code === value.language_code
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disableClearable
                  options={locLang.data.data.locations}
                  onChange={(e, val) =>
                    setSite({ ...site, location: val.location_code })
                  }
                  value={locLang.data.data.locations.find(
                    (loc) => loc.location_code === site.location,
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Country" />
                  )}
                  getOptionLabel={(option) => option.location_name}
                  isOptionEqualToValue={(option, value) =>
                    option.location_code === value.location_code
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton disabled={!!error} loading={loading} type="submit">
              Add
            </LoadingButton>
          </DialogActions>
        </form>
      ) : (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default SiteDialog;
