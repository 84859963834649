import { Grid, Paper } from "@mui/material";
import { TSite } from "../../../types/sites";
import PageList from "../../Page/List";
import SinglePage from "../../Page/Single";
import { useParams } from "react-router-dom";
import { useLayoutEffect, useRef } from "react";

type SingleSiteDisplayProps = {
  site: TSite;
};

const SingleSiteDisplay = ({ site }: SingleSiteDisplayProps) => {
  const params = useParams();
  const div = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (!div.current) return;
    const divAnimate = div.current.getBoundingClientRect().top;
    const width = div.current.getBoundingClientRect().width;
    const onScroll = () => {
      if (!div.current) return;
      if (divAnimate < window.scrollY + 50) {
        div.current.style.position = "fixed";
        div.current.style.top = "100px";
        div.current.style.width = `${width}px`;
        div.current.style.maxHeight = `${window.innerHeight - 120}px`;
        div.current.style.overflowY = "auto";
      } else {
        div.current.style.position = "relative";
        div.current.style.top = "0";
        div.current.style.height = `auto`;
        div.current.style.overflowY = "auto";
      }
    };
    window.addEventListener("scroll", onScroll);
    onScroll();
    return () => window.removeEventListener("scroll", onScroll);
  }, [params]);

  const { pageId } = useParams();
  return (
    <Grid container spacing={3}>
      <Grid item xs={7}>
        <Paper>
          <PageList site={site} />
        </Paper>
      </Grid>
      <Grid item xs={5}>
        {pageId && (
          <Paper sx={{ p: 2 }} ref={div}>
            <SinglePage />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default SingleSiteDisplay;
