import { IconButton } from "@mui/material";
import { useState } from "react";
import SiteDialog from "./SiteDialog";
import SettingsIcon from "@mui/icons-material/Settings";

type EditSiteProps = {
  id: string;
};

const EditSite = ({ id }: EditSiteProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <SettingsIcon />
      </IconButton>
      {open && <SiteDialog open={open} setOpen={setOpen} id={id} />}
    </>
  );
};

export default EditSite;
