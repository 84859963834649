import { Box, CircularProgress, Typography } from "@mui/material";
import { TSite } from "../../../types/sites";

type SingleSiteEmptyProps = {
  site: TSite;
};
const SingleSiteEmpty = ({ site }: SingleSiteEmptyProps) => {
  return (
    <Box sx={{ mt: 8, textAlign: "center" }}>
      <Box
        sx={{
          maxWidth: 600,
          mx: "auto",
        }}
      >
        <CircularProgress disableShrink sx={{ color: "#444" }} size={60} />
        <Typography variant="h2" sx={{ mt: 3 }}>
          No data recorded
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Please add the following code to your site
        </Typography>

        <Box
          sx={{
            wordBreak: "break-all",
            mt: 3,
            px: 2,
            py: 1,
            background: "#fff",
            border: "1px solid #777",
          }}
        >
          &lt;script type="text/javascript" src="https://px.metamod.app/
          {site.id}" defer&gt;&lt;/script&gt;
        </Box>
        <Typography variant="body1" sx={{ mt: 2 }}>
          If you have already implemented the pixel, please allow some time to
          get data flowing.
        </Typography>
      </Box>
    </Box>
  );
};

export default SingleSiteEmpty;
