import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@mui/material";

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number; target: number; width: number },
) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 80, width: "auto", textAlign: "center" }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.width,
        )}/${props.target}px`}</Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
