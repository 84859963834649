const API_URL = process.env.REACT_APP_API_URL;
const SERP_CONFIG = {
  title: {
    min: 50,
    max: 60,
    warn: 0.8,
  },
  desc: {
    min: 120,
    max: 160,
    warn: 0.85,
  },
};

export { API_URL, SERP_CONFIG };
