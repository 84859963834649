import { Box, Grid } from "@mui/material";

type FullScreenProps = {
  children: JSX.Element | JSX.Element[];
};

const FullScreen = ({ children }: FullScreenProps) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        background: "#fff",
        zIndex: 9999,
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <Grid item>
          <Box
            sx={{
              width: 400,
              bgcolor: "background.paper",
              border: "0px",
              boxShadow: 0,
              p: 4,
              outline: "none",
              my: 0,
              textAlign: "center",
            }}
          >
            {children}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default FullScreen;
