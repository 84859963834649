import GoogleIcon from "@mui/icons-material/Google";
import { Button } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useExchangeCodeMutation } from "../../store/rtk/metamod";
import FullScreen from "../Utils/FullScreen";

const GoogleLogin = () => {
  const [exchangeCode] = useExchangeCodeMutation();
  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        exchangeCode(code);
      } catch (err) {
        console.log(err);
      }
    },
    flow: "auth-code",
  });
  return (
    <FullScreen>
      <Button onClick={googleLogin} startIcon={<GoogleIcon />}>
        Log in with google
      </Button>
    </FullScreen>
  );
};

export default GoogleLogin;
