import { Box, Typography } from "@mui/material";
import { TPage } from "../../types/sites";

type SerpItemProps = {
  page: TPage;
};

const SerpItem = ({ page }: SerpItemProps) => {
  try {
    const url = new URL(page.href);
    const breadCrumbs = url.pathname
      .split("/")
      .filter((segment) => segment !== "");
    return (
      <Box>
        <Typography variant="serpHostname">{url.hostname}</Typography>
        <Typography variant="serpBreadcrumbs">
          {url.origin}{" "}
          {breadCrumbs.length > 0 ? ` › ${breadCrumbs.join(" › ")}` : ""}
        </Typography>
        <Typography variant="serpTitle">{page.title}</Typography>
        <Typography variant="serpDesc">
          {page.desc || "No description set!"}
        </Typography>
      </Box>
    );
  } catch (error) {
    return null;
  }
};

export default SerpItem;
