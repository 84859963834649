import { CircularProgress, Typography } from "@mui/material";
import FullScreen from "./FullScreen";

type FullScreenLoaderProps = {
  text?: string;
};

const FullScreenLoader = ({ text }: FullScreenLoaderProps) => {
  return (
    <FullScreen>
      <>
        <CircularProgress />
        {text && (
          <Typography variant="body1" sx={{ mt: 3 }}>
            {text}
          </Typography>
        )}
      </>
    </FullScreen>
  );
};
export default FullScreenLoader;
