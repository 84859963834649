import { Button } from "@mui/material";
import { useState } from "react";
import SiteDialog from "./SiteDialog";

const AddSite = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>Add site</Button>
      {open && <SiteDialog open={open} setOpen={setOpen} />}
    </>
  );
};

export default AddSite;
