import { useState } from "react";
import { useGetPagesQuery } from "../../store/rtk/metamod";
import { TSite } from "../../types/sites";
import FullScreen from "../Utils/FullScreen";
import {
  Button,
  CircularProgress,
  List,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import PageListItem from "./ListItem";

type PageListProps = {
  site: TSite;
};
const PageList = ({ site }: PageListProps) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const pages = useGetPagesQuery({
    siteId: site.id,
    page: page,
    perPage: perPage,
  });
  if (!pages.isSuccess) {
    return (
      <FullScreen>
        <CircularProgress />
      </FullScreen>
    );
  }
  if (pages.data.status !== "success") {
    return (
      <FullScreen>
        <Typography variant="body1">An error occurred</Typography>
      </FullScreen>
    );
  }

  return (
    <>
      <List disablePadding>
        {pages.data.data.map((p) => (
          <PageListItem key={p.id} page={p} />
        ))}
      </List>
      {site._count && site._count.pages > perPage && (
        <>
          Page {page + 1} of {Math.ceil(site._count.pages / perPage)}
          <Button
            onClick={() => {
              setPage(page - 1);
            }}
            disabled={page < 1}
          >
            Previous
          </Button>
          <Button
            onClick={() => {
              setPage(page + 1);
            }}
            disabled={page + 1 >= Math.ceil(site._count.pages / perPage)}
          >
            Next
          </Button>
          <Select
            label="Per Page"
            value={perPage}
            onChange={(e) => {
              setPerPage(Number(e.target.value));
            }}
          >
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="100">100</MenuItem>
            <MenuItem value="200">200</MenuItem>
            <MenuItem value="500">500</MenuItem>
          </Select>
        </>
      )}
    </>
  );
};

export default PageList;
