import { CircularProgress, Typography } from "@mui/material";
import { useGetPagesQuery, useGetSitesQuery } from "../../../store/rtk/metamod";
import SingleSiteEmpty from "./Empty";
import FullScreen from "../../Utils/FullScreen";
import { useParams } from "react-router-dom";
import SingleSiteDisplay from "./Display";
import EditSite from "../Edit";

const SingleSite = () => {
  const { siteId } = useParams();
  const sites = useGetSitesQuery(void 0, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const pages = useGetPagesQuery(
    {
      siteId: siteId || "",
      page: 0,
      perPage: 30,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  );

  if (!sites.isSuccess) {
    return (
      <FullScreen>
        <CircularProgress />
      </FullScreen>
    );
  }
  if (sites.data.status !== "success") {
    return (
      <FullScreen>
        <Typography variant="body1">An error occurred</Typography>
      </FullScreen>
    );
  }
  const site = sites.data.data.find((site) => site.id === siteId);
  if (!site) {
    return (
      <FullScreen>
        <Typography variant="body1">An error occurred</Typography>
      </FullScreen>
    );
  }
  const empty =
    pages.data?.status === "success" && pages.data.data.length > 0
      ? false
      : true;
  return (
    <>
      <Typography variant="h1" sx={{ mb: 3 }}>
        <EditSite id={site.id} />
        {site.domain}
      </Typography>
      {empty ? (
        <SingleSiteEmpty site={site} />
      ) : (
        <SingleSiteDisplay site={site} />
      )}
    </>
  );
};

export default SingleSite;
