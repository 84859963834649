import { Button } from "@mui/material";
import { useLogoutMutation } from "./store/rtk/metamod";
import { Route, Routes } from "react-router-dom";
import Frame from "./Components/Utils/Frame";
import SingleSite from "./Components/Site/Single";

function App() {
  const [logout] = useLogoutMutation();

  return (
    <>
      <Routes>
        <Route path="/" element={<Frame />}>
          <Route path="/sites">
            <Route path="/sites/:siteId">
              <Route
                path="/sites/:siteId/page/:pageId"
                element={<SingleSite />}
              />
              <Route index element={<SingleSite />} />
            </Route>
            <Route index element={<p>Sites dashboard</p>} />
          </Route>
          <Route index element={<p>Index</p>} />
        </Route>
      </Routes>
      <div className="App">
        <header className="App-header">
          <br />
          <Button
            onClick={() => {
              logout();
            }}
          >
            Log out
          </Button>

          {/* {sites.map((site) => {
          return (
            <p>
              <a
                href="javascript:void(0)"
                onClick={() => addSite(site.siteUrl)}
              >
                {site.domain}
              </a>
            </p>
          );
        })} */}
        </header>
      </div>
    </>
  );
}

export default App;
