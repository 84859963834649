import { useEffect, useState } from "react";
import { useGetLoggedInQuery } from "../../store/rtk/metamod";
import FullScreenLoader from "../Utils/FullScreenLoader";
import GoogleLogin from "./GoogleLogin";

type AuthCheckProps = {
  children: JSX.Element;
};

const AuthCheck = ({ children }: AuthCheckProps): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const { data, error, isLoading } = useGetLoggedInQuery(undefined, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!isLoading) {
      setLoaded(true);
    }
  }, [isLoading]);

  if (isLoading && !loaded) {
    return <FullScreenLoader text="Please wait..." />;
  }
  if (error || !data?.data.loggedin) {
    return <GoogleLogin />;
  }
  return children;
};

export default AuthCheck;
