import { Grid, ListItem, ListItemButton } from "@mui/material";
import { TPage } from "../../types/sites";
import { useNavigate, useParams } from "react-router-dom";
import SerpItem from "./SerpItem";
import BulletIcon from "@mui/icons-material/Circle";
import { useEffect, useState } from "react";
import getWidth, { StatusItem } from "../../utils/getWidth";

type PageListItemProps = {
  page: TPage;
};

const PageListItem = ({ page }: PageListItemProps) => {
  const [status, setStatus] = useState<StatusItem["color"]>("success");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!page || !page.title || !page.desc) {
      setStatus("error");
      return;
    }
    const title = getWidth(page.modifications[0]?.title || page.title);
    const desc = getWidth(page.modifications[0]?.desc || page.desc, "desc");
    if (desc.color === "error" || title.color === "error") {
      setStatus("error");
    } else if (desc.color === "warning" || title.color === "warning") {
      setStatus("warning");
    } else {
      setStatus("success");
    }
  }, [page]);

  const serp = { ...page };
  if (page.modifications[0]) {
    serp.title = page.modifications[0].title;
    serp.desc = page.modifications[0].desc;
  }

  return (
    <ListItem key={page.id} disablePadding disableGutters>
      <ListItemButton
        selected={Number(params.pageId) === page.id}
        onClick={() => navigate(`/sites/${params.siteId}/page/${page.id}`)}
      >
        <Grid container spacing={2}>
          <Grid item xs={1} sx={{ textAlign: "right", pt: 2 }}>
            <BulletIcon color={status} />
          </Grid>
          <Grid item xs={8}>
            <SerpItem page={serp} />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
  );
};

export default PageListItem;
